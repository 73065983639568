<template>
  <div class="inner-section">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('ditfConfig.stall_info') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(stall_no)="data">
                    {{ $n(data.item.stall_no, { useGrouping: false }) }}
                  </template>
                  <template v-slot:cell(status)="data">
                      <span v-if="parseInt(data.item.status) === 1">
                        <span class="badge badge-primary">{{ $t('ditfAppTradeFairManages.available') }}</span>
                      </span>
                      <span v-if="parseInt(data.item.status) === 2">
                        <span class="badge badge-success"> {{ $t('ditfAppTradeFairManages.assign') }}</span>
                      </span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-b-modal.modal-4 variant=" action-btn status" size="sm" @click="detailsData(data.item)"><i class="ri-eye-fill"></i></b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-4" size="lg" :title="$t('ditfAppTradeFairManages.applicant_stall')" hide-footer ok-only ok-variant="danger">
      <template #modal-title class="flex-grow-1">
        {{ $t('externalTradeFair.circular_fair_participation') + ' ' + $t('globalTrans.details') }}
        <b-button variant="success" @click="pdfExport(item)" class="mr-2 float-right">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <ApplyStall :stallId="stall_id" :key="stall_id" :item="item" ref="details"/>
    </b-modal>
  </div>
</template>
<script>
import BreadCumb from '@/components/BreadCumb.vue'
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { categoryWiseStallList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import ApplyStall from './ApplyStall'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ApplyStall, BreadCumb
  },
  data () {
    return {
      valid: '',
      sortBy: '',
      stall_id: '',
      item: [],
      search: {
        limit: 20
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1 },
        { labels: 'ditfConfig.stall_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2 },
        { labels: 'ditfConfig.stall_location', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3 },
        { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4 },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 5 }
      ],
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    listData: function () {
      const tmpData = this.$store.state.list
      return JSON.parse(JSON.stringify(tmpData))
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'stall_no' },
          { key: 'stall_location_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'stall_no' },
          { key: 'stall_location_en' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    searchData () {
      this.loadData()
    },
    detailsData (item) {
      this.item = item
      this.stall_id = item.id
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage }, { stall_cat_det_id: this.$route.query.stall_cat_det_id })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, categoryWiseStallList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    pdfExport (item) {
      this.$refs.details.pdfExport(item)
    }
  }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
